import React, { createContext, useContext } from 'react';

interface ApplicationStateProps {
  queryParameters: { [name: string]: string };
  urlPath?: string;
}

const ApplicationState = createContext<ApplicationStateProps>({ queryParameters: {} });

const getQueryParameters = (): { [name: string]: string } => {
  const querySearchParams = window.location.search;
  const parameterList = querySearchParams.replace('?', '').split('&');
  const parameters: { [name: string]: string } = parameterList.reduce((acc, cur) => {
    const parameterComponents = cur.split('=');
    if (parameterComponents.length !== 2) {
      return acc;
    }
    return {
      ...acc,
      [decodeURIComponent(parameterComponents[0])]: decodeURIComponent(parameterComponents[1]),
    };
  }, {});

  return parameters;
};

const getUrlPath = (): string => window.location.pathname;

export const ApplicationStateProvider = ({ children }: { children: React.ReactNode }) => {
  const queryParameters = getQueryParameters();
  const urlPath = getUrlPath();

  return <ApplicationState.Provider value={{ queryParameters, urlPath }}>{children}</ApplicationState.Provider>;
};

export const useApplicationState = () => {
  const context = useContext(ApplicationState);
  return context;
};
