import React from 'react';
import * as S from './Styles';

interface BulletListProps {
  items: { text: string }[];
}

const BulletList: React.FC<BulletListProps> = ({ items }) => {
  return (
    <S.BulletContainer>
      {items.map((item, index) => (
        <S.Bullet key={index}>
          <S.BulletText variant="body">{item.text}</S.BulletText>
        </S.Bullet>
      ))}
    </S.BulletContainer>
  );
};

export default BulletList;
