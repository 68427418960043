import React from 'react';
import * as S from './Styles';

const CustomPhoneAnimation = () => {
  // Due to byte range issues in Safari and large video sizes, we are using image instea for now.

  return (
    <S.PhoneContainer>
      <S.PhoneImage src={'/resources/MobileImage.png'} alt="Mobile showcase" />
      {/* <S.PhoneFrame>
        <video autoPlay muted loop playsInline>
          <source src="/resources/MobileVideo.mp4" type="video/mp4" />
          <source src="/resources/MobileVideo.hevc.mp4" type="video/mp4" />
          <source src="/resources/MobileVideo.webm" type="video/webm" />
        </video>
      </S.PhoneFrame> */}
    </S.PhoneContainer>
  );
};

export default CustomPhoneAnimation;
