import React from 'react';
import { useApplicationState } from '../../ApplicationState';
import { createIosDownloadLink, createAndroidDownloadLink } from '../../appstoreLinks';
import * as S from './Styles';

interface DownloadProps {
  className?: any;
}

const DownloadLinks = ({ className }: DownloadProps) => {
  const { queryParameters } = useApplicationState();

  return (
    <S.DownloadOptions className={className}>
      <S.Option>
        <S.Link href={createIosDownloadLink(queryParameters)} target="_blank">
          <S.Image src={'/resources/apple-app-store.svg'} alt="App Store Logo" />
        </S.Link>
      </S.Option>
      <S.Option>
        <S.Link href={createAndroidDownloadLink(queryParameters)} target="_blank">
          <S.Image src={'/resources/google-play-store.svg'} alt="Google Play Logo" />
        </S.Link>
      </S.Option>
    </S.DownloadOptions>
  );
};

export default DownloadLinks;
