import React from 'react';
import * as S from './Styles';

const VideoAnimation = () => {
  // Due to byte range issues in Safari and large video sizes, we are using image instea for now.

  return (
    <S.Container>
      {/* <S.SupportImage src={'/resources/SupportFull.svg'} alt="Support illustration" />
      <S.PhoneImage src={'/resources/DesktopImage.png'} alt="Desktop showcase" /> */}
      <S.Image src={'/resources/Group.png'} alt="Desktop showcase" />
      {/* <video autoPlay muted loop playsInline>
        <source src="/resources/DesktopAnimation.mov" type="video/mp4" />
      </video> */}
    </S.Container>
  );
};

export default VideoAnimation;
