import { useApplicationState } from './ApplicationState.tsx';
import { createIosDownloadLink, createAndroidDownloadLink } from './appstoreLinks.tsx';

const userAgent = navigator.userAgent || navigator.vendor || window.opera;
const isAndroidDevice = /android/i.test(userAgent);
const isIosDevice = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

const RedirectController = ({ children }) => {
  const { queryParameters, urlPath } = useApplicationState();

  if (/\/last-ned(\/)?/.test(urlPath)) {
    if (isAndroidDevice) {
      const redirectUrl = createAndroidDownloadLink(queryParameters);
      window.location.href = redirectUrl;
    } else if (isIosDevice) {
      const redirectUrl = createIosDownloadLink(queryParameters);
      window.location.href = redirectUrl;
    }
  }

  return children;
};

export default RedirectController;
