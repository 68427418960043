import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';

export const PhoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${spacing.space20};

  @media (min-width: ${breakpoints.l}) {
    padding: ${spacing.space20};
  }

  @media (min-width: ${breakpoints.m}) {
    margin: ${spacing.space40};
  }

  @media (max-width: ${breakpoints.s}) {
    margin: auto;
  }
`;

export const PhoneFrame = styled.div`
  position: relative;
  width: 300px;
  height: 600px;
  background-color: #000;
  border-radius: ${spacing.space24};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);

  @media (min-width: ${breakpoints.m}) {
    width: 360px;
    height: 720px;
  }

  @media (max-width: ${breakpoints.m}) {
    width: 280px;
    height: 560px;
  }

  @media (max-width: ${breakpoints.s}) {
    width: 260px;
    height: 520px;
  }

  video {
    position: absolute;
    top: ${spacing.space8};
    left: ${spacing.space8};
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    border-radius: ${spacing.space16};
    object-fit: fill;
  }
`;

export const PhoneImage = styled.img`
  width: 100%;
  height: 100%;
`;
