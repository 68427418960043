import styled from 'styled-components';
import { breakpoints } from '@naf/theme';

export const Container = styled.div`
  position: relative;
  width: 120%;
  top: -30px;
  left: -10%;

  @media (max-width: ${breakpoints.l}) {
    left: 70%;
    width: 20%;
  }

  /* video {
    width: 130%;
    height: auto;
  } */
`;

export const Image = styled.img`
  width: 130%;
`;

// export const PhoneImage = styled.img`
//   /* position: absolute; */
//   left: -10%;
//   width: 200%;

//   @media (max-width: ${breakpoints.l}) {
//     left: 70%;
//     width: 20%;
//   }
// `;
