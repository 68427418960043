import styled from 'styled-components';
import { Text } from '@naf/text';
import { nafColor } from '@nafcore/theme';
import { spacing, breakpoints } from '@naf/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing.space64};
  background-image: url('./resources/Background.png');
  background-size: cover;
  background-position: center;

  @media (max-width: ${breakpoints.l}) {
    padding: ${spacing.space8};
    padding-bottom: ${spacing.space80};
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${spacing.space32};
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: ${breakpoints.l}) {
    grid-template-columns: 1fr;
    padding: 20px;
  }
`;

export const GridContent = styled.div``;

export const Header = styled(Text)`
  color: ${nafColor.primary.spruce};
  font-size: 4.5rem;
  position: relative;
  z-index: 2;

  @media (max-width: ${breakpoints.m}) {
    font-size: 2rem;
  }
`;

export const Ingress = styled(Text)`
  color: ${nafColor.primary.spruce};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.48px;
  margin-bottom: ${spacing.space32};
  position: relative;
  z-index: 2;
  max-width: 400px;
`;

export const SubTitle = styled(Text)`
  color: ${nafColor.primary.spruce};
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.56px;
`;

export const DownloadContainer = styled.div`
  padding-top: ${spacing.space32};

  @media (max-width: ${breakpoints.m}) {
    padding-top: ${spacing.space8};
  }
`;

export const DesktopVideoContainer = styled.div`
  display: block;
  position: relative;
  z-index: 1;

  @media (max-width: ${breakpoints.l}) {
    display: none;
  }
`;

export const ToggleContainer = styled.div`
  display: none;

  @media (max-width: ${breakpoints.l}) {
    display: flex;
    justify-content: flex-end;
  }
`;

export const ToggleButton = styled.button`
  background-color: ${nafColor.signature.white};
  border: none;
  border-radius: 50px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 5;
  margin-top: -${spacing.space16};
  box-shadow: 0px 10px 20px -3px ${nafColor.signature.black}(0, 0, 0, 0.1);

  svg {
    color: ${nafColor.signature.black} !important;
  }

  @media (min-width: ${breakpoints.m}) {
    margin-right: 120px;
  }

  @media (min-width: ${breakpoints.s}) {
    margin-right: 80px;
  }

  @media (max-width: ${breakpoints.m}) {
    bottom: -10px;
    right: 10px;
  }
`;

export const MobileVideoContainer = styled.div<{ isExpanded?: boolean }>`
  display: none;

  @media (max-width: ${breakpoints.l}) {
    display: flex;
    /* height: ${({ isExpanded }) => (isExpanded ? '50%' : '20%')}; */
    overflow: hidden;
    transition: height 0.6s ease;
    position: relative;
    z-index: 1;
  }
`;

export const AnimatedPhone = styled.div`
  height: 100%;
  width: 100%;
`;
