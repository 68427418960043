export const bulletContent = [
  {
    text: 'Samle bilene, få varsler og bestill service',
  },
  {
    text: 'Enkleste måte å bestille Veihjelp',
  },
  {
    text: 'Medlemskort og våre beste fordeler',
  },
];
