import styled from 'styled-components';
import { Text } from '@naf/text';
import { nafColor } from '@nafcore/theme';
import { spacing, breakpoints } from '@naf/theme';

export const BulletContainer = styled.ul`
  padding: 0;
  margin-bottom: ${spacing.space32};
  position: relative;
  z-index: 2;
`;

export const Bullet = styled.li`
  list-style: none;
  position: relative;
  padding-left: ${spacing.space32};

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-color: ${nafColor.primary.kart};
    border-radius: 50%;
  }
`;

export const BulletText = styled(Text)`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.48px;

  @media (max-width: ${breakpoints.m}) {
    font-size: 1.25rem;
  }
`;
