import React from 'react';
import NafLogo from '../components/Logo/NafLogo';
import BulletList from '../components/BulletList/BulletList';
import { bulletContent } from '../components/BulletList/BulletListContent';
import DownloadLinks from '../components/DownloadLinks/DownloadLinks';
import CustomPhoneAnimation from '../components/Animation/CustomPhoneAnimation/CustomPhoneAnimation';
import VideoAnimation from '../components/Animation/VideoAnimation/VideoAnimation';
// import { Eye } from '@styled-icons/ionicons-outline/Eye';
// import { EyeOff } from '@styled-icons/ionicons-outline/EyeOff';
import * as S from './Styles';

const Home = () => {
  // const [isPhoneExpanded, setPhoneExpanded] = useState(false);

  // const togglePhone = () => {
  //   setPhoneExpanded((prev) => !prev);
  // };

  // Due to byte range issues in Safari and large video sizes, we are using image instea for now.
  // Original functionality for video is commented out sice we want this in the future.

  return (
    <S.Container>
      <S.Content>
        <S.GridContent>
          <NafLogo />
          <S.Header variant="header1">
            Mindre stress <br />
            rundt bilen
          </S.Header>
          <S.Ingress variant="header2">
            Med NAF-appen får du varsler om EU- kontroll, oversikt over alle bilene og mindre stress. Si hei til et
            enklere bilhold.
          </S.Ingress>
          <S.MobileVideoContainer>
            <S.AnimatedPhone>
              <CustomPhoneAnimation />
            </S.AnimatedPhone>
          </S.MobileVideoContainer>
          {/* <S.ToggleContainer>
            <S.ToggleButton onClick={togglePhone}>
              {isPhoneExpanded ? <EyeOff height="24" width="24" /> : <Eye height="24" width="24" />}
            </S.ToggleButton>
          </S.ToggleContainer> */}
          <BulletList items={bulletContent} />
          <S.DownloadContainer>
            <S.SubTitle variant="header3">Last ned appen</S.SubTitle>
            <DownloadLinks />
          </S.DownloadContainer>
        </S.GridContent>
        <S.DesktopVideoContainer>
          <VideoAnimation />
        </S.DesktopVideoContainer>
      </S.Content>
    </S.Container>
  );
};

export default Home;
