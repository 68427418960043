import styled from 'styled-components';
import { spacing, radius, breakpoints } from '@naf/theme';

export const Link = styled.a`
  display: flex;
`;

export const DownloadOptions = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  z-index: 2;

  @media (max-width: 380px) {
    flex-direction: column;
    margin: 0;
    align-items: center;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: ${spacing.space56};
  object-fit: contain;
  border-radius: ${radius.m};

  @media (max-width: ${breakpoints.m}) {
    height: ${spacing.space48};
  }
`;

export const Option = styled.div`
  &:first-child {
    margin-right: ${spacing.space8};

    @media (max-width: 380px) {
      margin-right: 0px;
      margin-bottom: ${spacing.space8};
    }
  }
`;
