import React from 'react';
import { ThemeProvider } from '@naf/theme-provider';
import { GlobalStyle } from '@naf/global-style';
import { ApplicationStateProvider } from './ApplicationState';
import RedirectController from './RedirectController';
import Home from './pages/Home';

const App = () => {
  return (
    <ThemeProvider>
      <ApplicationStateProvider>
        <RedirectController>
          <GlobalStyle />
          <Home />
        </RedirectController>
      </ApplicationStateProvider>
    </ThemeProvider>
  );
};

export default App;
